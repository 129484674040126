// export const baseUrl = 'https://www.angus-publishing.com/'
export const baseUrl = process.env.NODE_ENV === 'development'
  ? 'http://192.168.1.30:9111/'
  : 'https://451c1adf.build-site.cn/'
//
export const fileScene = {
  office: '.pdf, .doc, .docx, .xls, .xlsx',
  image: '.png, .jpg, .gif, .psd, .svg',
  video: '.mp4, .rmvd, .mkv, .avi, .mp3'
}
