<template>
  <div>
    <!--  缩略图-->
    <div v-if="type === 'image'">
      <el-upload
        class="upload-demo"
        :action="action"
        :accept="accept"
        :limit="limit"
        :data="{fileType: type}"
        list-type="picture"
        :headers="headers"
        :file-list="files"
        :on-remove="handleRemove"
        :on-success="success">
        <el-button class="el-icon-plus avatar-uploader-icon" v-if="files.length < limit"></el-button>
      </el-upload>
    </div>

    <!--  office和video -->
    <div v-else>
      <el-upload
        class="upload-demo"
        :action="action"
        :accept="accept"
        :data="{fileType: type}"
        list-type="text"
        :headers="headers"
        :file-list="files"
        :on-remove="handleRemove"
        :on-success="success">
        <el-button class="el-icon-plus avatar-uploader-icon" v-if="files.length < limit"></el-button>
      </el-upload>
    </div>
  </div>
</template>
<script>

import { baseUrl, fileScene } from '@/utils/config'

export default {
  name: 'UploadFile',
  props: {
    type: {
      type: String,
      required: true
    },
    files: {
      // 数据类型
      type: Array,
      // 约束是否必须传值
      required: true
    },
    field: {
      // 数据类型
      type: String,
      // 约束是否必须传值
      required: true
    },
    limit: {
      // 数据类型
      type: Number,
      // 约束是否必须传值
      required: true
    }
  },
  data () {
    return {
      action: baseUrl + 'Common/File/Upload',
      headers: {
        Authorization: sessionStorage.getItem('token')
      },
      accept: ''
    }
  },
  created () {
    this.accept = fileScene[this.type]
  },
  methods: {
    handleRemove: function (file, fileList) {
      this.changeUpload(fileList)
    },
    success: function (response, file, fileList) {
      this.changeUpload(fileList)
    },
    changeUpload: function (list) {
      const result = []
      for (let i = 0; i < list.length; i++) {
        const item = list[i]
        if (item.status !== 'success') break
        if ('response' in item && item.response.code === 200) {
          result.push(item.response.data)
        } else {
          result.push(item)
        }
      }
      this.$emit('event', this.field, result)
    }
  }
}
</script>

<style scoped lang="less">

</style>
